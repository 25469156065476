import React from "react"
import { RadioGroup, RadioGroupProps, Radio } from "@chakra-ui/core"
import { useFormContext } from "react-hook-form"

type RadioGroupFieldProps = RadioGroupProps & {
  isInvalid?: boolean
  isRequired?: boolean
  options: { label: string; value: string }[]
}

const RadioGroupField = React.forwardRef(function RadioGroupField(
  { isInvalid, options, name, ...props }: RadioGroupFieldProps,
  ref: RadioGroupProps["ref"]
) {
  return (
    <RadioGroup display="flex" variantColor="primarySet" name={name} {...props}>
      {options.map(({ label, value }) => (
        <Radio
          ref={ref}
          key={value}
          name={name}
          value={value}
          variantColor="primarySet"
          borderColor={isInvalid ? "sunrise" : "primary"}
          mr="1rem"
        >
          {label}
        </Radio>
      ))}
    </RadioGroup>
  )
})

export default RadioGroupField

export function ConnectedRadioGroupField({
  isRequired,
  ...props
}: RadioGroupFieldProps) {
  const { register, errors } = useFormContext()
  return (
    <RadioGroupField
      {...props}
      ref={register({ required: isRequired })}
      isInvalid={!!errors[props.name || ""]}
    />
  )
}
