import React, { useState } from "react"
import { graphql } from "gatsby"
import {
  Box,
  Flex,
  BoxProps,
  FlexProps,
  SimpleGrid,
  AspectRatioBox,
  Icon,
  PseudoBox,
  FormControl,
  FormLabel,
} from "@chakra-ui/core"
import GatsbyImage, { FluidObject, FixedObject } from "gatsby-image"

import Heading from "../../components/typography/Heading"
import Body from "../../components/typography/Body"
import MaxWidthGrid from "../../components/Layout/MaxWidthGrid"

import { bpWithSidebar, bp } from "../../utils/MediaQueries"
import { AmbassadorPageQuery, Maybe } from "../../../graphql-types"
import SEO from "../../components/boilerplate/seo"
import PageContainer from "../../components/PageContainer"
import { useIsSideBarOpen } from "../../hooks/useIsSidebarOpen"
import ExternalLink from "../../components/ExternalLink"
import Slider from "../../components/Slider"
import styled from "@emotion/styled"
import useGoogleForm from "../../hooks/useGoogleForm"
import { FormProvider } from "react-hook-form"
import FormBox from "../../components/ContactForms/common/FormBox"
import SuccessWrapper from "../../components/ContactForms/forms/SuccessWrapper"
import FormFieldsLayout from "../../components/ContactForms/common/FormFieldsLayout"
import { ConnectedTextField as TextField } from "../../components/FormFields/TextField"
import { ConnectedDateField as DateField } from "../../components/FormFields/DateField"
import { ConnectedRadioGroupField as RadioGroupField } from "../../components/FormFields/RadioGroupField"
import { ConnectedCheckboxField as Checkbox } from "../../components/FormFields/CheckboxField"
import { Button } from "../../components/Buttons"

export const query = graphql`
  query AmbassadorPage {
    contentfulPage(slug: { eq: "become-a-brand-ambassador" }) {
      contentful_id
      title
      slug
      seoTitle
      seoDescription {
        seoDescription
      }
    }
    banner: file(relativePath: { eq: "ambassador-page/header.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iconBag: file(relativePath: { eq: "ambassador-page/icon_bag.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iconBike: file(relativePath: { eq: "ambassador-page/icon_bike.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iconBox: file(relativePath: { eq: "ambassador-page/icon_box.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iconCheers: file(relativePath: { eq: "ambassador-page/icon_cheers.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iconDiscount: file(
      relativePath: { eq: "ambassador-page/icon_discount.png" }
    ) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iconEye: file(relativePath: { eq: "ambassador-page/icon_eye.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iconGift: file(relativePath: { eq: "ambassador-page/icon_gift.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iconPhone: file(relativePath: { eq: "ambassador-page/icon_phone.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    apply1: file(relativePath: { eq: "ambassador-page/apply1.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    apply2: file(relativePath: { eq: "ambassador-page/apply2.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    apply3: file(relativePath: { eq: "ambassador-page/apply3.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    apply4: file(relativePath: { eq: "ambassador-page/apply4.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ambassador1: file(
      relativePath: { eq: "ambassador-page/nazirasacasa.png" }
    ) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ambassador2: file(relativePath: { eq: "ambassador-page/rosaalexc.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    video1: file(relativePath: { eq: "ambassador-page/youtube1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    video2: file(relativePath: { eq: "ambassador-page/youtube2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    video3: file(relativePath: { eq: "ambassador-page/youtube3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    video4: file(relativePath: { eq: "ambassador-page/youtube4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

type AmbassadorPageProps = {
  data: AmbassadorPageQuery
  location: Location
}

const Divider: React.FC<BoxProps> = (props) => (
  <Box h="2px" w="9.75rem" mx="auto" backgroundColor="black" {...props} />
)

const IconItem: React.FC<
  FlexProps & { icon: AmbassadorPageQuery["iconBag"] }
> = ({ icon, children, ...props }) => (
  <Flex
    flexDir="column"
    align="center"
    justify="center"
    maxW="16.375rem"
    {...props}
  >
    {icon?.childImageSharp?.fixed && (
      <GatsbyImage
        fixed={icon.childImageSharp.fixed as FixedObject}
        style={{ width: "100%" }}
        imgStyle={{ objectFit: "contain" }}
      />
    )}
    <Heading as="span" size="6" textAlign="center" mt="2rem">
      {children}
    </Heading>
  </Flex>
)

const AmbassadorItem: React.FC<
  FlexProps & {
    image: FixedObject
    name: string
    tag: string
    quote: React.ReactNode
    isSidebarOpen: boolean
  }
> = ({ image, name, tag, quote, isSidebarOpen, ...props }) => {
  const bpSpecial = (wide: any, vertical: any) => [
    vertical,
    null,
    null,
    null,
    null,
    isSidebarOpen ? vertical : wide,
    wide,
  ]
  return (
    <Flex flexDirection={bpSpecial("row", "column")} maxW="100%" {...props}>
      <GatsbyImage
        fixed={image}
        style={{ maxWidth: "100%" }}
        imgStyle={{
          objectFit: "contain",
          objectPosition: "center",
          maxWidth: "100%",
        }}
      />
      <Box
        width={bpSpecial("50%", "100%")}
        pt="2.25rem"
        pl={bpSpecial("2.25rem", "0")}
      >
        <Heading as="span" size="6" fontWeight="bold" mb="1rem" w="100%">
          {name}
        </Heading>
        <Body
          fontWeight="bold"
          mb={bpWithSidebar("1rem", "2.5625rem", isSidebarOpen)}
          w="100%"
        >
          {tag}
        </Body>
        <Body w="100%" maxW="24.8125rem">
          &ldquo;{quote}&rdquo;
        </Body>
      </Box>
    </Flex>
  )
}

const ResponsiveIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const YouTubeSlide: React.FC<{
  videoSrc: string
  image?: Maybe<FluidObject>
}> = ({ videoSrc, image }) => {
  const [clicked, setClicked] = useState(false)

  return (
    <Box pos="relative" w="100%">
      <AspectRatioBox ratio={16 / 9} w="100%" maxW="100%" mx="auto">
        {clicked ? (
          <ResponsiveIframe
            className="yt_player_iframe"
            src={`${videoSrc}&autoplay=1&enablejsapi=1`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <Box
            role="group"
            w="100%"
            h="100%"
            cursor="pointer"
            onClick={() => setClicked(true)}
          >
            <Flex
              pos="absolute"
              top="0"
              left="0"
              zIndex={1}
              w="100%"
              h="100%"
              align="center"
              justify="center"
            >
              <PseudoBox
                w="100px"
                h="100px"
                backgroundColor="rgba(0,0,0,0.5)"
                borderRadius="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                _groupHover={{ opacity: 0.5 }}
              >
                <Icon
                  name="play"
                  color="white"
                  size="50px"
                  transform="translateX(4px)"
                />
              </PseudoBox>
            </Flex>
            {image && (
              <GatsbyImage
                fluid={image}
                style={{ height: "100%" }}
                imgStyle={{ objectFit: "cover" }}
              />
            )}
          </Box>
        )}
      </AspectRatioBox>
    </Box>
  )
}

export default function AmbassadorPage({
  data,
  location,
}: AmbassadorPageProps) {
  const bannerImage = data.banner?.childImageSharp?.fluid as FluidObject
  const isSidebarOpen = useIsSideBarOpen()
  const { methods, isSubmitting, success, error, onSubmit } = useGoogleForm<{}>(
    {
      sheetsId: "1vKK19VukrhrD36UaUCwwcYlig9D0yrKp8MO_u4YpQh0",
    }
  )

  const stopYouTubeVideo = () => {
    const iframes = document.querySelectorAll(".yt_player_iframe")
    if (iframes) {
      iframes.forEach((iframe: any) => {
        iframe.contentWindow.postMessage(
          '{"event":"command","func":"' + "stopVideo" + '","args":""}',
          "*"
        )
      })
    }
  }

  return (
    <PageContainer pt="0">
      <SEO
        title={data.contentfulPage?.seoTitle || "Become a Brand Ambassador"}
        location={location}
      />
      {/* Hero */}
      <Box pos="relative" mb="80">
        {bannerImage && <GatsbyImage fluid={bannerImage} loading="eager" />}
        <Flex
          pos="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          zIndex={1}
          align="center"
          justify="center"
        >
          <Heading color="white" textAlign="center">
            {data.contentfulPage?.title || "Become a Brand Ambassador"}
          </Heading>
        </Flex>
      </Box>
      <MaxWidthGrid>
        <Box gridColumn="1/end">
          {/* Description */}
          <Body textAlign="center" w="35rem" maxW="100%" mx="auto" mb="1rem">
            At sixthreezero, our goal is to get as many people pedaling as we
            possibly can so everybody can keep moving happy forever.
          </Body>
          <Body textAlign="center" w="35rem" maxW="100%" mx="auto">
            If you want to be a part of this cruiser community and spread the
            word about our cool, comfortable and affordable bikes, you’re at the
            right place :)
          </Body>
          <Divider mt="8.25rem" mb="8.75rem" />
          {/* Why Section */}
          <Heading as="h2" size="3" textAlign="center" mb="6.25rem">
            Why Become A Sixthreezero Ambassador?
          </Heading>
          <SimpleGrid
            columns={bpWithSidebar(2, 4, isSidebarOpen)}
            alignItems="baseline"
            justifyItems="center"
            gridColumnGap="1rem"
            gridRowGap="3.4375rem"
            px="2rem"
          >
            <IconItem icon={data.iconBike}>
              Be a part of a fun Pedal Ambassadors Community
            </IconItem>
            <IconItem icon={data.iconBag}>Free products</IconItem>
            <IconItem icon={data.iconDiscount}>
              Discount codes to share
            </IconItem>
            <IconItem icon={data.iconPhone}>
              Increase your IG following &amp; engagement
            </IconItem>
            <IconItem icon={data.iconGift}>VIP giveaways</IconItem>
            <IconItem icon={data.iconBox}>Gifts &amp; commissions</IconItem>
            <IconItem icon={data.iconEye}>
              Exclusive content &amp; sneak peeks
            </IconItem>
            <IconItem icon={data.iconCheers}>Fun events</IconItem>
          </SimpleGrid>
        </Box>
      </MaxWidthGrid>
      {/* How section  */}
      <Box backgroundColor="#F0F0F0" mt="10.4375rem" pt="9.375rem" pb="12.5rem">
        <MaxWidthGrid>
          <Box gridColumn="1/end">
            <Heading as="h2" size="3" textAlign="center" mb="6.25rem">
              How To Apply
            </Heading>
            <SimpleGrid
              columns={bpWithSidebar(1, 4, isSidebarOpen)}
              alignItems="baseline"
              justifyItems="center"
              gridColumnGap="1rem"
              gridRowGap="3.4375rem"
              px="2rem"
            >
              <IconItem icon={data.apply1}>
                Follow us on{" "}
                <ExternalLink
                  to="https://www.instagram.com/_sixthreezero_/"
                  color="primary"
                >
                  @_sixthreezero_
                </ExternalLink>{" "}
                and like us on{" "}
                <ExternalLink
                  to="https://www.facebook.com/sixthreezero.co"
                  color="primary"
                >
                  Sixthreezero Bicycle Co
                </ExternalLink>
              </IconItem>
              <IconItem icon={data.apply2}>
                Fill out the Ambassadors form below
              </IconItem>
              <IconItem icon={data.apply3}>
                Wait to get approved and follow the steps requested by email
              </IconItem>
              <IconItem icon={data.apply4}>
                Welcome to our pedal community 🙂
              </IconItem>
            </SimpleGrid>
          </Box>
        </MaxWidthGrid>
      </Box>
      <MaxWidthGrid pt="11.5rem">
        <Box gridColumn="1/end">
          {/* Ambassadors */}
          <Heading as="h2" size="3" textAlign="center" mb="6.25rem">
            Meet Our Ambassadors
          </Heading>
          <Flex
            px={bp("0", "2rem")}
            flexDirection={bpWithSidebar("column", "row", isSidebarOpen)}
            align={bpWithSidebar("center", "flex-start", isSidebarOpen)}
            justify="center"
          >
            <AmbassadorItem
              image={data.ambassador1?.childImageSharp?.fixed as FixedObject}
              name="Nazira"
              tag="@nazirasacasa"
              quote={
                <>
                  I’m sooooo excited for my new bike! When I lived in NYC I
                  would ride my bike everywhere and I really missed having a
                  bike. Since I moved to Santa Monica a few months ago I started
                  riding again and it’s feels amazing to ride to the beach and
                  spend time outside!
                </>
              }
              isSidebarOpen={isSidebarOpen}
              mr={bpWithSidebar("0", "2rem", isSidebarOpen)}
              mb={bpWithSidebar("4rem", "0", isSidebarOpen)}
              flexGrow={0}
            />
            <AmbassadorItem
              image={data.ambassador2?.childImageSharp?.fixed as FixedObject}
              name="Rosa"
              tag="@rosaalexc"
              quote={
                <>
                  Life is like riding a bicycle; you have to keep moving if you
                  want to keep your balance!! Thanks to my friends over at
                  <ExternalLink
                    to="https://www.instagram.com/_sixthreezero_/"
                    color="primary"
                    mx="0.25rem"
                  >
                    @_sixthreezero_
                  </ExternalLink>{" "}
                  I’ll be reaching my destination much faster on my new wheels
                  🚲 “Reach Your Destination” looks like a #cruiserbike but it’s
                  a hybrid; perfect for riding around town or hitting the
                  trails.
                </>
              }
              isSidebarOpen={isSidebarOpen}
              flexGrow={0}
            />
          </Flex>
          <Divider my="8.25rem" />
          {/* YouTube videos */}
          <Box
            pos="relative"
            mx={bpWithSidebar("1rem", "3rem", isSidebarOpen)}
            mb="10rem"
          >
            <Slider
              containerProps={{
                px: bpWithSidebar("4rem", "5rem", isSidebarOpen),
              }}
              onNext={() => {
                stopYouTubeVideo()
              }}
              onPrev={() => {
                stopYouTubeVideo()
              }}
              items={[
                <YouTubeSlide
                  key="0"
                  videoSrc="https://www.youtube.com/embed/UemP0BkqiH4?start=280"
                  image={data.video1?.childImageSharp?.fluid as FluidObject}
                />,
                <YouTubeSlide
                  key="1"
                  videoSrc="https://www.youtube.com/embed/JXawdct5hkE?start=540"
                  image={data.video2?.childImageSharp?.fluid as FluidObject}
                />,
                <YouTubeSlide
                  key="2"
                  videoSrc="https://www.youtube.com/embed/l9hedeP7sGY?start=348"
                  image={data.video3?.childImageSharp?.fluid as FluidObject}
                />,
                <YouTubeSlide
                  key="3"
                  videoSrc="https://www.youtube.com/embed/qexW3vVfyiI?start=742"
                  image={data.video4?.childImageSharp?.fluid as FluidObject}
                />,
              ]}
            >
              {({ Slides, Dots }) => (
                <Box>
                  {Slides}
                  {Dots}
                </Box>
              )}
            </Slider>
          </Box>
        </Box>
      </MaxWidthGrid>
      {/* Application Form */}
      <Box backgroundColor="#F0F0F0" pt="9.375rem" pb="12.5rem">
        <MaxWidthGrid>
          <Box gridColumn="1/end">
            <Heading as="h2" size="3" textAlign="center" mb="4rem">
              Application Form
            </Heading>
            <FormProvider {...methods}>
              <FormBox
                boxShadow="none"
                maxWidth="920px"
                mx="auto"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <SuccessWrapper
                  success={success}
                  message={
                    <>
                      <Box as="span" display="block">
                        Thanks for your interest in our Pedal Ambassadors
                        Program.
                      </Box>
                      <Box as="span" display="block">
                        All applications will be carefully considered by our
                        team
                      </Box>
                      <Box as="span" display="block">
                        and you’ll be contacted shortly if approved 🙂
                      </Box>
                    </>
                  }
                >
                  <FormFieldsLayout mb="3rem">
                    <TextField
                      name="First Name"
                      placeholder="First Name"
                      isRequired
                    />
                    <TextField
                      name="Last Name"
                      placeholder="Last Name"
                      isRequired
                    />
                    <TextField
                      name="Email"
                      placeholder="Email"
                      type="email"
                      isRequired
                    />
                    <TextField
                      name="Instagram"
                      placeholder="Instagram"
                      isRequired
                    />
                    <TextField
                      name="YouTube"
                      placeholder="YouTube (Optional)"
                    />
                    <DateField
                      name="Birthdate"
                      placeholder="Birthdate (Must be over 18 years)"
                      isRequired
                      datePickerProps={{
                        showFullMonthYearPicker: true,
                      }}
                    />
                    <TextField name="City" placeholder="City" isRequired />
                    <TextField name="State" placeholder="State" isRequired />
                    <FormControl>
                      <FormLabel>Are you US-based?</FormLabel>
                      <RadioGroupField
                        name="usBased"
                        isRequired
                        options={[
                          { label: "Yes", value: "yes" },
                          { label: "No", value: "no" },
                        ]}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>
                        Are you willing to post about Sixthreezero a minimum of
                        1-2 times per week on social channels?
                      </FormLabel>
                      <RadioGroupField
                        name="postFrequency"
                        isRequired
                        options={[
                          { label: "Yes", value: "yes" },
                          { label: "No", value: "no" },
                        ]}
                      />
                    </FormControl>
                  </FormFieldsLayout>
                  <Checkbox name="followingSocials" isRequired mb="1rem">
                    Your application will only be considered if you follow
                    @_sixthreezero_ on IG and Sixthreezero Bicycle Co. on FB.
                  </Checkbox>
                  <Checkbox name="agreeToS" isRequired mb="1rem">
                    I agree to the program terms and policies.
                  </Checkbox>
                  <Checkbox
                    name="applicationAcknowledgement"
                    isRequired
                    mb="1rem"
                  >
                    I understand that this application has to be approved by
                    Sixthreezero Bicycle Co. and only after receiving a
                    confirmation email and following all the steps I will be a
                    part of the Ambassadors Program.
                  </Checkbox>
                  <Box textAlign="center" mt="4.32rem">
                    <Button
                      theme="primary"
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      Apply
                    </Button>
                  </Box>
                  {error && (
                    <Body mt="2rem" color="sunrise">
                      {error}
                    </Body>
                  )}
                </SuccessWrapper>
              </FormBox>
            </FormProvider>
          </Box>
        </MaxWidthGrid>
      </Box>
    </PageContainer>
  )
}
