import React from "react"
import { CheckboxProps, Checkbox} from "@chakra-ui/core"
import { useFormContext } from "react-hook-form"

type CheckboxFieldProps = CheckboxProps & {
  isInvalid?: boolean
  isRequired?: boolean
}

const CheckboxField = React.forwardRef(function CheckboxField(
  { isInvalid, name, ...props }: CheckboxFieldProps,
  ref: CheckboxProps["ref"]
) {
  return (
    <Checkbox ref={ref} variantColor="primarySet" name={name} borderColor={isInvalid ? "sunrise" : "primary"} {...props} />
  )
})

export default CheckboxField

export function ConnectedCheckboxField({
  isRequired,
  ...props
}: CheckboxFieldProps) {
  const { register, errors } = useFormContext()
  return (
    <CheckboxField
      {...props}
      ref={register({ required: isRequired })}
      isInvalid={!!errors[props.name || ""]}
    />
  )
}
